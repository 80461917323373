import { get } from '@truefit/http-utils';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useCallback, useEffect } from 'react';
import useTimemachineState, { TimemachineState } from '../hooks/useTimemachineState';
import ServerStatus from './ServerStatus';
import { ClipModel, ClipStatus } from '@/features/clips/types';
import DownloadClipDialog from '@/shared/components/DownloadClipDialog';

type Props = {
  children: ReactNode;
};

export const TimemachineStateContext = React.createContext<TimemachineState>(undefined);

function formatStatus(state: TimemachineState) {
  if (state.status) {
    if (state.clip) {
      if (state.clip.note) {
        return `Exporting  ${state.clip.note} : ${state.status} `;
      } else {
        return `Exporting Clip ${state.clip.id} : ${state.status} `;
      }
    }
    return `Exporting : ${state.status}`;
  }
  return '';
}

type DownloadRequest = {
  open: boolean;
  clip?: ClipModel | undefined;
};
const TimeMachineStatusProvider = ({ children }: Props) => {
  const { timemachineState } = useTimemachineState();

  const { enqueueSnackbar } = useSnackbar();

  const [downloadClipDialogOpen, setDownloadClipDialogOpen] = React.useState<DownloadRequest>({
    open: false,
    clip: undefined,
  });

  useEffect(() => {
    const { status, clip } = timemachineState || {};
    if (status === ClipStatus.READY) {
      setDownloadClipDialogOpen({ open: true, clip });
      enqueueSnackbar('Export complete', { variant: 'success', preventDuplicate: true });
    }
    if (status === ClipStatus.ERROR) {
      enqueueSnackbar('Export failed', { variant: 'error', preventDuplicate: true });
    }
    if (status === ClipStatus.CANCELED) {
      enqueueSnackbar('Export Canceled', { variant: 'info', preventDuplicate: true });
    }
  }, [timemachineState]);

  const handleCancelClick = useCallback(() => {
    (async () => {
      try {
        await get('unlock');
      } catch (e) {
        enqueueSnackbar('Network error', { variant: 'error', preventDuplicate: true });
      }
    })();
  }, [enqueueSnackbar]);

  return (
    <TimemachineStateContext.Provider value={timemachineState}>
      {timemachineState && timemachineState.locked && (
        <ServerStatus
          title="Export operation in progress"
          message="Please hold tight while we process your export task. Your clips will be trimmed to a length of 60 seconds."
          secondaryMessage={formatStatus(timemachineState)}
          cancellable
          open
          onYes={handleCancelClick}
          useDialog
          requiresConfirmation
        />
      )}

      <DownloadClipDialog
        open={downloadClipDialogOpen.open}
        clip={downloadClipDialogOpen.clip as ClipModel}
        onClose={() => setDownloadClipDialogOpen({ open: false })}
      />
      {children}
    </TimemachineStateContext.Provider>
  );
};

export default TimeMachineStatusProvider;
