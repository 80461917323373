import { differenceInSeconds, format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  Collapse,
  CollapseProps,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { grey } from '@mui/material/colors';
import { Videocam } from '@mui/icons-material';

import useCognito from '../../authentication/hooks/useCognito';
import { PANEL_PADDING } from '../../games/constants';
import { ClipModel, ClipType, isClip } from '../types';
import ClipRecordContextMenu from './ClipRecordContextMenu';
import ClipRecordForm from './ClipRecordForm';

import DisplayTags from '@/features/tags/components/DisplayTags';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { MANUAL_TRACKING_OR_NO_TRACKING } from '@/shared/hooks/useServerState';
import displayWallClock from '@/shared/services/displayWallClock';
import ExpandToggleButton from '@/shared/components/ExpandToggleButton';
import useAutoIsoEntitiesQuery from '@/features/autoIso/hooks/useAutoIsoEntitiesQuery';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

import notFoundLogo from '@/assets/not-found.jpg';
import ClipStatus from '@/shared/components/ClipStatus';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { TeamModel } from '@/features/teams/types/TeamModel';

type Props = {
  clip: ClipModel;
  isFirst: boolean;
  teams?: TeamModel[];
  collapseProps?: CollapseProps;
  downloadCliphandler: (clip: ClipModel) => Promise<void>; // Make it async
} & {
  // Add additional prop validations
  'aria-label'?: string;
  'data-testid'?: string;
};

const isValidTimestamp = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

const getDuration = (start: Date, end: Date): string => {
  if (!isValidTimestamp(start) || !isValidTimestamp(end)) {
    return '';
  }

  try {
    const diffInSeconds = differenceInSeconds(end, start);
    if (diffInSeconds < 0) {
      console.warn('End date is before start date');
      return '';
    }

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.floor(diffInSeconds % 60);

    return [hours, minutes, seconds].map((unit) => unit.toString().padStart(2, '0')).join(':');
  } catch (error) {
    console.error('Error calculating duration:', error);
    return '';
  }
};

const ClipRecord = ({
  clip,
  isFirst,
  teams = [],
  collapseProps = { sx: {} },
  downloadCliphandler,
}: Props) => {
  const { state, handleSubmit, handleCancelEditing, handleSetClipRecordRef, handleView } =
    useClipStateContext();
  const serverState = useServerStateContext();
  const cognito = useCognito();

  const clipRecordRef = useRef<HTMLElement>(null);

  const gameTypeMetadata = useGameTypeMetadataQuery();
  const autoIsoQuery = useAutoIsoEntitiesQuery();

  const autoIsoEntities = autoIsoQuery.data?.list;
  const editing = state.editingModel?.id === clip.id;
  const isEditingSomeOtherClip = state.isEditing && !editing;
  const isOwnClip = clip.clouduser?.email === cognito.userAttributes?.email;
  const trackedObject = autoIsoEntities?.find((entity) => entity?.id === clip.objectTrackingId);
  const cameras = Object.values(serverState?.availableCameras || {});

  const [expanded, setExpanded] = useState(false);
  const [displayDescription, setDisplayDescription] = useState(!!clip.description && !editing);
  const [clipDescription, setClipDescription] = useState(clip.description);

  const trackedEntity = useMemo(() => {
    if (!clip?.objectTrackingId) return '-';

    if (clip.objectTrackingId === 1) {
      return gameTypeMetadata.data?.objectOfPlay;
    }

    if (trackedObject?.type === 'Player') {
      return `${trackedObject.playerNumber} - ${trackedObject.displayName} (${trackedObject.playerPosition})`;
    }

    return `${trackedObject?.displayName}`;
  }, [clip.objectTrackingId, trackedObject, gameTypeMetadata.data?.objectOfPlay]);

  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    handleSetClipRecordRef(clip.id, clipRecordRef);
  }, [clip.id, handleSetClipRecordRef]);

  const handleError = (error: Error) => {
    console.error('Error in ClipRecord:', error);
    // Implement error reporting logic
  };

  const handleEditSubmit = async (values: ClipModel) => {
    try {
      await handleSubmit(values);
      setClipDescription(values.description);
      setDisplayDescription(values.description.length > 0);
    } catch (error) {
      handleError(error as Error);
    }
  };

  const getTeamLogo = React.useCallback(() => {
    if (trackedObject?.type === 'Player' && trackedObject.playerTeamId) {
      const team = teams.find((t) => t.id === trackedObject.playerTeamId);
      if (team) return getTeamLogoPath(team);
    }
    return notFoundLogo;
  }, [trackedObject, teams]);

  const getCameraNameByCameraId = React.useCallback(
    (cameraId: number) => {
      const camera = cameras.find((c) => c.idx === cameraId);
      if (camera) return camera.name;
      return 'Multi Camera';
    },
    [cameras],
  );

  const clipDuration = useMemo(() => {
    if (editing) {
      return getDuration(
        state.editingModel?.startTimestamp as Date,
        state.editingModel?.endTimestamp as Date,
      );
    }
    return getDuration(clip.startTimestamp, clip.endTimestamp as Date);
  }, [
    clip.startTimestamp,
    clip.endTimestamp,
    state.editingModel?.startTimestamp,
    state.editingModel?.endTimestamp,
  ]);

  return (
    <Stack>
      {!isEditingSomeOtherClip && (
        <Stack
          ref={clipRecordRef as React.RefObject<HTMLDivElement>}
          sx={(theme) => ({
            cursor: 'pointer',
            backgroundColor: editing ? theme.palette.background.default : undefined,
            opacity: isEditingSomeOtherClip ? 0.5 : 0.8,
            '&:hover': {
              opacity: 1,
            },
          })}
          onMouseEnter={() => setMenuVisible(true)}
          onMouseLeave={() => setMenuVisible(false)}
        >
          {!isFirst && <Divider />}

          <Stack ml={PANEL_PADDING - 1} mr={1.5}>
            <Stack direction="column" mt={1}>
              <Stack
                direction="row-reverse"
                alignItems="center"
                sx={{ flex: 1, marginBottom: 1, padding: 0.2 }}
              >
                {/* context menu */}

                <ClipRecordContextMenu
                  visible={menuVisible}
                  clip={clip}
                  exportClickHandler={() => setExpanded(true)}
                  downloadCliphandler={() => downloadCliphandler(clip)}
                />
                {isClip(clip) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: 1 }}>
                    <ClipStatus status={clip.status} />
                  </Box>
                )}
                {/* clip duration OR bookmark timestamp */}
                {isClip(clip) ? (
                  <Tooltip
                    title={`${displayWallClock(clip.startTimestamp)} - ${displayWallClock(
                      clip.endTimestamp,
                    )}`}
                    placement="left-start"
                  >
                    <Chip
                      label={clipDuration}
                      variant="outlined"
                      size="small"
                      color={editing ? 'warning' : 'success'}
                    />
                  </Tooltip>
                ) : (
                  <Chip
                    label={displayWallClock(clip.startTimestamp)}
                    variant="outlined"
                    size="small"
                    color="info"
                  />
                )}

                {/* clip note */}
                <Box
                  sx={{
                    overflowWrap: 'break-word',
                    width: '200px',
                  }}
                  flexGrow={1}
                  onClick={() => handleView(clip)}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    style={{ textTransform: 'capitalize' }}
                    sx={{ flex: 1 }}
                  >
                    {clip.note}
                  </Typography>
                </Box>

                <ExpandToggleButton
                  expanded={expanded}
                  onClickHandler={() => setExpanded(!expanded)}
                  disabled={isEditingSomeOtherClip}
                />
              </Stack>
            </Stack>
          </Stack>
          <Collapse
            sx={{
              flexShrink: 0,
              backgroundColor: grey[800],
              ...(collapseProps.sx || {}),
            }}
            in={expanded && !isEditingSomeOtherClip}
          >
            {clip.type === ClipType.Clip && (
              <Box p={2}>
                <Box
                  display="flex"
                  sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}
                >
                  <Chip
                    size="small"
                    icon={<Videocam />}
                    label={getCameraNameByCameraId(clip.cameraId)}
                  />

                  {!!clip.objectTrackingId && trackedObject?.type === 'Player' && (
                    <Chip
                      avatar={<Avatar alt="TrackedObject" src={getTeamLogo()} />}
                      label={trackedEntity}
                      variant="outlined"
                    />
                  )}

                  {!!clip.objectTrackingId && trackedObject?.type === 'BallOrPuck' && (
                    <Chip label={trackedEntity} variant="outlined" />
                  )}
                </Box>

                {(!clip.objectTrackingId ||
                  clip.objectTrackingId === MANUAL_TRACKING_OR_NO_TRACKING.id) &&
                  clip.pan && (
                    <Typography variant="subtitle2">
                      PTZ: {clip.pan} / {clip.tilt} / {clip.zoom}
                    </Typography>
                  )}

                {displayDescription && (
                  <Box
                    sx={{
                      overflowWrap: 'break-word',
                      width: '100%',
                      paddingBottom: 0,
                      paddingTop: 0,
                      paddingRight: 2,
                      paddingLeft: 2,
                    }}
                  >
                    <Typography variant="subtitle2">{clipDescription}</Typography>
                  </Box>
                )}
              </Box>
            )}

            {clip.type === ClipType.Bookmark && displayDescription && (
              <Box
                p={2}
                sx={{
                  overflowWrap: 'break-word',
                  width: '100%',
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 2,
                }}
              >
                <Typography variant="subtitle2">{clipDescription}</Typography>
              </Box>
            )}
            <Box p={2}>
              <Typography variant="caption" color={grey[500]}>
                {clip.clouduser.email.toLowerCase()} &#183;&nbsp;
                {format(clip.createdAt, 'PP')}
              </Typography>
            </Box>
          </Collapse>
          <Stack>
            {editing && (
              <Box p={2}>
                <ClipRecordForm
                  clip={clip}
                  onCancel={handleCancelEditing}
                  onSubmit={handleEditSubmit}
                />
              </Box>
            )}
            {!editing && (
              <Box px={1.6}>
                <DisplayTags clip={clip} readonly={!isOwnClip || isEditingSomeOtherClip} />
              </Box>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ClipRecord;
