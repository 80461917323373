import { useCallback, useEffect, useRef, useState } from 'react';

declare global {
  interface Window {
    manager?: GameStateManager;
    webrtcLogs: boolean;
  }
}

import { Tracking } from '@/shared/services/tracking';
// features imports
import useCamerasQuery from '@/features/camera/hooks/useCamerasQuery';

// websocket imports
import { useSelectCamRef } from './websocket/useSelectCam';
import { useSelectMosaicRef } from './websocket/useSelectMosaic';

import { Cameras } from '@/features/camera/types/CameraSettingsState';
import { ServerState, ViewType } from '@/features/camera/types/VideoSource';
import { usePTZSocket } from './websocket/usePTZSocket';
import { useSetTrackingTargetRef } from './websocket/useTrackEntity';
import { usePTZStore } from '../../features/camera/store/usePTZStore';
import { GetWebsocketUrl } from '@/util';
import { GameStateManager } from '@/features/gameState/GameStateManager';
import { TargetConstants } from '../types';

export const DEFAULT_CAMERA_SETTINGS_STATE: Cameras = [];

export const MANUAL_TRACKING_OR_NO_TRACKING = {
  id: -1,
  displayName: 'Manual Tracking',
  type: 'Manual',
};
export const OBJECT_OF_PLAY = { id: 1, displayName: 'Ball/Puck', type: 'BallOrPuck' };

export const MOSAIC_VIEW = ViewType.MOSAIC;

export const DEFAULT_SERVER_STATE: ServerState = {
  cameras: [],
  selectedCamera: -1,
  viewType: ViewType.NONE,
};

const ViewSourceToViewType = (source: string): ViewType => {
  switch (source) {
    case 'mosaic':
      return ViewType.MOSAIC;
    case 'autoTally':
      return ViewType.AUTO_TALLY;
    case 'single':
      return ViewType.SINGLE_CAMERA;
    default:
      return ViewType.NONE;
  }
};

/**
 * Supplies state tracking on top of the {@link useServerConnection} hook.
 * @returns functions for altering the server's state
 */
export const useServerState = () => {
  const url = GetWebsocketUrl('/state/0.1.0');

  const [singleCurrentlyTrackedObjectId, setSingleCurrentlyTrackedObjectId] = useState<number>(
    MANUAL_TRACKING_OR_NO_TRACKING.id,
  );
  // Since the API currently doesn't provide a way for us to know what video source the
  // server is starting with when the app loads, we need to manage the cameras query here.
  const cameraSettingsStateQuery = useCamerasQuery();
  const [serverState, setServerState] = useState<ServerState>(DEFAULT_SERVER_STATE);

  //websocket operations
  const [, setSelectCam] = useSelectCamRef();
  const [, setSelectMosaic] = useSelectMosaicRef();
  const [, setTrackingTarget] = useSetTrackingTargetRef();

  const { init: initPTZStore } = usePTZStore();
  const { setValue: enterManualMode } = usePTZSocket();

  // handleChange on the State
  const { handleChange: updatePTZstore } = usePTZStore();

  const trackEntity = useCallback((ent: any, receivedSource: string) => {
    let source: string = receivedSource;

    if (!ent) throw new Error('Entity must be defined');
    if (!ent.id) throw new Error('Entity must have an id');

    const { id } = ent;

    if (ent.id === MANUAL_TRACKING_OR_NO_TRACKING.id) {
      source = 'Manual Tracking';
      enterManualMode({ pan: 0, tilt: 0, zoom: 0 });
    } else {
      if (setTrackingTarget) {
        setTrackingTarget(id);
      }
    }

    if (source) {
      Tracking.getInstance().track('Switch Tracked Entity', {
        category: 'Iso',
        source,
        ent,
      });
    }
  }, []);

  const selectMosaic = useCallback((source: string) => {
    if (setSelectMosaic) {
      setSelectMosaic({ dims: { width: 0, height: 0 }, idxs: [] });
    }

    Tracking.getInstance().track('Switch View', {
      source,
      category: 'Controls',
      type: 'mosaic',
    });
  }, []);

  const selectCamera = useCallback((idx: number, source: string) => {
    if (setSelectCam) {
      setSelectCam(idx);
    }

    Tracking.getInstance().track('Switch View', {
      source,
      category: 'Controls',
      type: 'single',
    });
  }, []);

  useEffect(() => {
    const manager = new GameStateManager(url);
    manager.connect();
    window.manager = manager;
    cameraSettingsStateQuery.refetch();

    return () => {
      if (window.manager) {
        window.manager.disconnect();
        delete window.manager;
      }
    };
  }, []);

  useEffect(() => {
    if (cameraSettingsStateQuery.status === 'success' && cameraSettingsStateQuery.data.data) {
      initPTZStore(cameraSettingsStateQuery.data.data);

      setServerState((currentState) => ({
        ...currentState,
        cameras: cameraSettingsStateQuery.data.data || DEFAULT_CAMERA_SETTINGS_STATE,
      }));
    }
  }, [cameraSettingsStateQuery.data]);

  useEffect(() => {
    const handleVcamChange = (vcam: any) => {
      const { selectedCamera } = serverState;
      if (!updatePTZstore || typeof selectedCamera === 'undefined') return;
      updatePTZstore(vcam, selectedCamera);
    };

    const handleViewChange = (view: any) => {
      const { type, idx: cameraIndex } = view;
      const selectedIdx = typeof cameraIndex === 'number' ? cameraIndex : parseInt(cameraIndex, 10);

      setServerState((currentState) => {
        const newState = {
          ...currentState,
          selectedCamera: selectedIdx,
          viewType: ViewSourceToViewType(type),
        };

        // Prevent unnecessary state updates
        return JSON.stringify(currentState) !== JSON.stringify(newState) ? newState : currentState;
      });
    };

    const handleTrackingEntityUpdated = (target: any) => {
      switch (target.type) {
        case TargetConstants.AUTOMATIC:
          if (!target.id) throw new Error('Automatic tracking target must have an id');

          setSingleCurrentlyTrackedObjectId((currentId) =>
            currentId !== target.id ? target.id : currentId,
          );
          setServerState((currentState) => {
            const updatedCameras =
              currentState.cameras?.map((camera) => ({
                ...camera,
                trackingId: target.id,
              })) || currentState.cameras;

            return JSON.stringify(currentState.cameras) !== JSON.stringify(updatedCameras)
              ? { ...currentState, cameras: updatedCameras }
              : currentState;
          });
          break;
        case TargetConstants.MANUAL:
          setSingleCurrentlyTrackedObjectId(MANUAL_TRACKING_OR_NO_TRACKING.id);
          break;
        default:
          break;
      }
    };

    // Initialize GameStateManager if conditions are met
    if (window.manager) {
      window.manager.on('vcamChange', handleVcamChange);
      window.manager.on('viewChange', handleViewChange);
      window.manager.on('trackingTargetChange', handleTrackingEntityUpdated);
    }
    // Cleanup function
    return () => {
      if (window.manager) {
        window.manager.off('vcamChange', handleVcamChange);
        window.manager.off('viewChange', handleViewChange);
        window.manager.off('trackingTargetChange', handleTrackingEntityUpdated);
        window.manager.disconnect();
        delete window.manager;
      }
    };
  }, []);

  const serverStateAndFunctions = {
    gameStateManager: window.manager,
    availableCameras: serverState.cameras, //ok
    trackEntity,
    selectCamera,
    selectMosaic,
    singleCurrentlyTrackedObjectId, //ok
    selectedCameraId: serverState.selectedCamera, // ok
    selectedCamera: serverState.cameras[serverState.selectedCamera], // ok
    viewType: serverState.viewType, // ok
  };

  const serverStateAndFunctionsCopyRef = useRef(serverStateAndFunctions);

  useEffect(() => {
    serverStateAndFunctionsCopyRef.current = serverStateAndFunctions;
  }, [serverStateAndFunctions]);

  const getServerStateAndFunctions = useCallback(() => serverStateAndFunctionsCopyRef.current, []);

  return {
    ...serverStateAndFunctions,
    getServerStateAndFunctions,
  };
};

export type ServerStateAndFunctions = ReturnType<typeof useServerState>;

export default useServerState;
