import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';

import { green, grey, purple, red, orange } from '@mui/material/colors';
import { Squircle } from 'lucide-react';

interface ClipStatusProps {
  status: string;
  onClickHandler?: () => void;
}

const getChipStatusColor = (status: string): string => {

  switch (status.toUpperCase()) {
    case 'READY':
      return green['A700'];
    case 'ERROR':
      return red[700];
    case 'CANCELED':
      return grey[700];
    case 'PENDING':
      return orange[700];
    case 'UNPROCESSED':
      return grey[700];
    default:
      return purple[700];
  }
};

const getBorderColor = (status: string): string => {
  switch (status.toUpperCase()) {
    case 'READY':
      return green['A200'];
    case 'ERROR':
      return red[300];
    case 'CANCELED':
      return grey[300];
    case 'PENDING':
      return orange[300];
    case 'UNPROCESSED':
      return grey[300];
    default:
      return purple[300];
  }
};
const ClipStatus: React.FC<ClipStatusProps> = ({ status, onClickHandler }) => {
  const color = getChipStatusColor(status);
  const borderColor = getBorderColor(status);
  const title = status.charAt(0).toUpperCase() + status.slice(1);

  return (
    <MuiTooltip color={borderColor} title={title} arrow onClick={onClickHandler}>
      <Squircle color={borderColor} fill={color}  > </Squircle>
    </MuiTooltip>
  );
};

export default ClipStatus;
