import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { useQueryClient } from '@tanstack/react-query';
import { CognitoContext } from '@/features/authentication/components/CognitoProvider';
import { getQueryKey as getCurrentGameClipsQueryKey } from '@/features/clips/hooks/useCurrentGameClipsQuery';
import { GetITMSSEUrl } from '@/util';
import { ClipModel } from '@/features/clips/types';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

export type TimemachineState = {
  locked: boolean;
  clip?: ClipModel;
  status?: string;
};

const INITIAL_STATE: TimemachineState = { locked: false };

const useTimemachineState = () => {
  const { cognitoContext } = useContext(CognitoContext);
  const currentGameId = useCurrentGameId();
  const queryClient = useQueryClient();
  const [state, setState] = useState<TimemachineState>(INITIAL_STATE);
  const unlockTimeoutRef = useRef<NodeJS.Timeout>(null);
  const sseRef = useRef<ReconnectingEventSource | null>(null);

  const unlockUI = useCallback(() => {
    if (unlockTimeoutRef.current) clearTimeout(unlockTimeoutRef.current);
    setState({ locked: false });
  }, []);

  const lockUI = useCallback((stale: number) => {
    setState((prev) => ({ ...prev, locked: true }));
    if (stale) {
      unlockTimeoutRef.current = setTimeout(() => {
        setState({ locked: false });
      }, stale * 1000);
    }
  }, []);

  const handleSSEEvents = useCallback(
    (data: any) => {
      const { event, payload } = data;

      switch (event) {
        case 'clip-export-status-update': {
          const {
            clip,
            status,
          } = payload;

          setState((prev) => ({ ...prev, clip, status }));
          queryClient.refetchQueries({ queryKey: getCurrentGameClipsQueryKey(currentGameId) });
          break;
        }
        case 'unlock':
          unlockUI();
          break;
        case 'lock':
          lockUI(payload.stale);
          break;
        case 'game-switch-update':
          // Handle game switch updates here
          break;
      }
    },
    [lockUI, unlockUI],
  );

  useEffect(() => {
    if (!cognitoContext.authenticated) {
      if (unlockTimeoutRef.current) clearTimeout(unlockTimeoutRef.current);
      if (sseRef.current) {
        sseRef.current.close();
        sseRef.current = null;
      }
      return;
    }

    sseRef.current = new ReconnectingEventSource(GetITMSSEUrl());
    sseRef.current.onmessage = (e) => handleSSEEvents(JSON.parse(e.data));

    return () => {
      if (unlockTimeoutRef.current) clearTimeout(unlockTimeoutRef.current);
      if (sseRef.current) {
        sseRef.current.close();
        sseRef.current = null;
      }
    };
  }, [cognitoContext.authenticated, handleSSEEvents]);

  return { timemachineState: state };
};

export type TimemachineStateType = ReturnType<typeof useTimemachineState>;
export default useTimemachineState;
