import { TeamModel } from '@/features/teams/types/TeamModel';
import { defaultGcTime, defaultStaleTime } from '@/shared/constants';
import { isInvalid } from '@/shared/services/isInvalid';
import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

export function getQueryKey(leagueId?: number, seasonId?: number) {
  return ['GameTeams', leagueId, seasonId];
}

const useGameTeamsQuery = (leagueId?: number, seasonId?: number, opt?: any) =>
  useQuery({
    queryKey: getQueryKey(leagueId, seasonId),
    queryFn: async (): Promise<TeamModel[]> => {
      const url = seasonId ? `teams?seasonId=${seasonId}` : 'teams';
      const { data } = await get(url);
      return data;
    },
    staleTime: defaultStaleTime, // Data considered fresh for 30 seconds
    gcTime: defaultGcTime, // Data will be garbage collected after 5 minutes
    refetchOnWindowFocus: !isInvalid(leagueId, 'number'), // refetch ONLY if enabled and leagueId is valid
    enabled: !isInvalid(leagueId, 'number'),
    placeholderData: [],
    ...opt,
  });

export default useGameTeamsQuery;
