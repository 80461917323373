import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { HookFormDatePicker, HookFormSelect } from '../../forms/components';
import useGameTeamsQuery from '../hooks/useGameTeamsQuery';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { FormControl, InputLabel, Select, SelectChangeEvent, Stack } from '@mui/material';
import useListSeasonsQuery from '../hooks/useListSeasonsQuery';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { TeamModel } from '@/features/teams/types/TeamModel';

const STORAGE_KEY = 'games-form-state';

type Props = {
  onChange: (values: FormValues) => unknown;
  available: boolean;
};

export type FormValues = {
  date?: Date;
  team?: string;
  sort?: string;
  streamType: string;
  seasonId: string;
  leagueId?: string;
  orgId?: string;
  available: boolean;
};

export const DEFAULT_VALUES: FormValues = {
  date: undefined,
  team: undefined,
  sort: 'oldest',
  streamType: 'all',
  seasonId: ' ',
  leagueId: undefined,
  orgId: undefined,
  available: true,
};

type FieldsetProps = {
  legend: string;
  children: React.ReactNode;
};

const Fieldset = ({ legend, children }: FieldsetProps) => (
  <Box
    component="fieldset"
    sx={{
      border: 'none',
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      margin: 0,
      padding: 0,
    }}
  >
    <Typography variant="overline">{legend}</Typography>
    {children}
  </Box>
);

const GamesForm = ({ onChange, available }: Props) => {
  // Load initial form data from sessionStorage if it exists
  const loadStoredFormData = () => {
    try {
      const storedData = sessionStorage.getItem(STORAGE_KEY);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        // Convert stored date string back to Date object if it exists
        if (parsedData.date) {
          parsedData.date = new Date(parsedData.date);
        }
        return { ...DEFAULT_VALUES, ...parsedData };
      }
    } catch (error) {
      console.error('Error loading form data from sessionStorage:', error);
    }
    return DEFAULT_VALUES;
  };

  const methods = useForm<FormValues>({
    defaultValues: loadStoredFormData(),
  });

  const { watch, handleSubmit, control, register } = methods;
  const [currentSeason, setDefaultSeason] = useState('');
  const { currentLeague, currentOrg } = React.useContext(UserStateContext);

  const teamsQuery = useGameTeamsQuery(currentLeague?.id, parseInt(currentSeason));
  const listSeasonsQuery = useListSeasonsQuery(currentLeague?.id as number);

  // Save form data to sessionStorage whenever it changes
  const saveToLocalStorage = (formData: FormValues) => {
    try {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
    } catch (error) {
      console.error('Error saving form data to sessionStorage:', error);
    }
  };

  // Combine the original onChange handler with sessionStorage saving
  const handleFormChange = (values: FormValues) => {
    saveToLocalStorage(values);
    onChange(values);
  };

  useEffect(() => {
    if (listSeasonsQuery.status === 'success') {
      const savedValues = loadStoredFormData();
      const savedSeasonId = savedValues['seasonId'];
      if (savedSeasonId) {
        setDefaultSeason(savedSeasonId.toString());
        methods.setValue('seasonId', savedSeasonId.toString());
        return;
      }
      const defaultSeason = listSeasonsQuery.data.find((season) => season.is_active === true);
      if (defaultSeason) {
        setDefaultSeason(defaultSeason.id.toString());
        methods.setValue('seasonId', defaultSeason.id.toString());
      } else {
        setDefaultSeason(' ');
        methods.setValue('seasonId', '');
      }
    }
  }, [listSeasonsQuery.data]);

  useEffect(() => {
    if (currentLeague?.id) {
      methods.setValue('leagueId', currentLeague.id.toString());
      listSeasonsQuery.refetch();
      teamsQuery.refetch();
    }

    if (currentOrg?.id) {
      methods.setValue('orgId', currentOrg.id.toString());
    }
  }, [currentLeague, currentOrg]);

  // submits the form onChange
  // from https://stackoverflow.com/a/70119332
  useEffect(() => {
    const subscription = watch(handleSubmit(handleFormChange));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onChange]);

  return (
    <FormProvider {...methods}>
      <input value={currentLeague?.id} type="hidden" {...register('leagueId')} />
      <input value={currentOrg?.id} type="hidden" {...register('orgId')} />
      <input value={available} type="hidden" {...register('available')} />

      <Stack direction="column" justifyContent="space-between" spacing={2} p={3}>
        <Box sx={{ width: 300 }}>
          <Controller
            name="seasonId"
            control={control}
            render={({ field: { onChange: lOnChange, value } }) => {
              function handleSeasonChange(event: SelectChangeEvent<string>): void {
                setDefaultSeason(event.target.value);
                lOnChange(event.target.value);
              }

              return (
                <FormControl fullWidth>
                  <InputLabel>Season</InputLabel>
                  {(listSeasonsQuery.data?.length > 0) && (
                    <>
                      <Select
                        labelId="season-select-label"
                        id="season-select"
                        value={currentSeason}
                        label="Season"
                        onChange={handleSeasonChange}
                      >
                        <MenuItem value=" ">All Seasons</MenuItem>

                        {listSeasonsQuery.data.map((season) => (
                          <MenuItem key={season.id} value={season.id}>
                            {season.name}  {season.seasonType? ` - ${season.seasonType.toUpperCase()}` : ''}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              );
            }}
          ></Controller>
        </Box>

        <Box
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 4,
            justifyContent: 'space-between',
            borderWidth: 1,
            borderColor: 'red',
          }}
        >
          <Controller
            name="streamType"
            control={control}
            render={({ field: { onChange: lOnChange, value } }) => {
              const handleClick = (newValue: string) => () => lOnChange(newValue);
              const variant = (testValue: string) => (value === testValue ? 'filled' : 'outlined');
              const color = (testValue: string) =>
                value === testValue ? (value === 'live' ? 'error' : 'primary') : 'default';

              return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Chip
                    label="All"
                    variant={variant('all')}
                    onClick={handleClick('all')}
                    color={color('all')}
                  />

                  <Chip
                    label="Live"
                    variant={variant('live')}
                    onClick={handleClick('live')}
                    color={color('live')}
                  />

                  <Chip
                    label="Recorded"
                    variant={variant('recorded')}
                    onClick={handleClick('recorded')}
                    color={color('recorded')}
                  />
                  <Chip
                    label="Upcoming"
                    variant={variant('scheduled')}
                    onClick={handleClick('scheduled')}
                    color={color('scheduled')}
                  />
                </Box>
              );
            }}
          />

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Fieldset legend="Filter">
              <HookFormDatePicker
                name="date"
                defaultValue={null}
                TextFieldProps={{ label: 'Date', sx: { width: '200px' } }}
                DatePickerProps={{ label: 'Date', inputFormat: 'MM/dd/yyyy' }}
              />

              <Controller
                name="team"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    onChange={(_e, v: TeamModel) => {
                      field.onChange(v?.abbreviation || '');
                    }}
                    groupBy={(option) => option.abbreviation[0].toUpperCase()}
                    isOptionEqualToValue={(option, value) => option.abbreviation === value}
                    value={field.value}
                    id="team-selector"
                    getOptionLabel={(option: any) =>
                      option?.abbreviation ? option.abbreviation : option
                    }
                    options={teamsQuery.data}
                    renderOption={(props, option: any) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="35"
                          src={getTeamLogoPath(option)}
                          alt={option.abbreviation}
                        />
                        {option.abbreviation}
                      </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label="Team" size="medium" />}
                    sx={{ width: '200px' }}
                  />
                )}
              />
            </Fieldset>

            <Fieldset legend="Sort">
              <HookFormSelect name="sort" label="Time" margin="dense">
                <MenuItem value="newest">Newest</MenuItem>
                <MenuItem value="oldest">Oldest</MenuItem>
              </HookFormSelect>
            </Fieldset>
          </Box>
        </Box>
      </Stack>
    </FormProvider>
  );
};

export default GamesForm;
